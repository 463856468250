<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
      <div class="center-content-v3">
        <div class="form-verify-v3">
          <p class="mb-4 title-form-v3">Reset Password</p>
          <p class="mt-2 text-verify-v3 sub-title-form-v3" style="margin-bottom: 0;">We have sent the reset password link to your email.  Please check your inbox.</p>
          <p class="text-verify-v3 sub-title-form-v3">The password reset expires in 1 hour.</p>
          <p v-if="this.durations > 0" class="mt-2 sub-title-form-v3">Resend reset password link in {{ timeDisplay }}s</p>
          <button v-else  @click="startCounting()" class="mt-2 btn-resend-verify-v3 sub-title-form-v3">Resend reset password link</button>
          <p class="mt-4 sub-title-form-v3"><span><router-link tag="a" to="/login" class="blue-text-v3" href="">Back to Sign In</router-link></span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";
import { authMethods } from "@/state/helpers";

export default {
  name: 'ForgotPasswordMsg',
  components: {
    Navbar
  },
  data() {
    return {
      durations: 0,
      loading: false,
      authError: null,
      isAuthError: false,
      tryingToSendEmail: false,
      invalidEmail : "Your account or Email is incorrect.",
      stateEmail : true,
    }
  },
  computed: {
    timeDisplay() {
      const seconds = this.durations % 60
      return seconds
    }
  },
  mounted() {
    this.startCountingMount()
  },
  methods: {
    ...authMethods,
    startCountingMount() {
      this.durations = 30
      let interval = setInterval(() => {
        this.durations -= 1
        if(this.durations <= 0){
          clearInterval(interval)
        }
      }, 1000)
      this.timeDisplay
    },
    startCounting() {
      this.startCountingMount()
      this.sendResetPassword()
    },
    sendResetPassword() {
      this.loading = true;
      this.tryingToSendEmail = true;
      // Reset the authError if it existed.
      this.authError = null;
      this.isAuthError = false;
      var emailUser = this.$route.params.email

      return (
        this.forgotPassword({
          email: emailUser
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToSendEmail = false;
            this.isAuthError = true;
            this.authError = token;
            this.stateEmail = true;
            this.loading = false;
            this.$toasted.show(`Link has been sent. Please check your email.`,{ 
              theme: "toasted-primary", 
              position: "bottom-center", 
              duration : 2000
            })
          })
          .catch(error => {
            this.loading = false;
            this.tryingToSendEmail = false;
            this.invalidEmail = error ? error : "";
            this.stateEmail = false;
            this.isAuthError = false;
          })
      );
    }
  }
}
</script>

<style>

</style>
